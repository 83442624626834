import React from "react";
import chart1 from "../../../assets/img/about/chart1.webp";
import features from "../../../assets/img/about/features.svg";
import { TopCategories2 } from "./slider/Carousel";
import { Helmet } from "react-helmet";

const Edeekshaam = () => {
  return (
    <div className="main-wrapper">
      <Helmet>
        <title>About edeekshaam - Special Education Platform</title>
        <meta
          name="description"
          content="Learn about edeekshaam, an online platform connecting special children, parents, educators, and schools across India. We aim to enhance the quality of special education and support services."
        />
        <link
          rel="canonical"
          href="https://www.edeekshaam.in/about-edeeksham"
        />
      </Helmet>
      <div className="page-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">
                <span style={{ fontSize: "3rem" }}>ε</span>-Deekshaam
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div>
          <h2 style={{ textAlign: "justify" }}>
            Inclusive Education is our first priority
          </h2>
          <p style={{ textAlign: "justify" }} className="lh-lg">
            E-Deekshaam is an adaptable, assistive learning framework that can
            be used in both online and blended modes in two parts: the web-based
            system and a mobile application to accompany it. The framework would
            include content for children aged 6 to 18. The system will help
            teachers and parents by generating personalized individualized
            Education Programs which the teachers can later modify to suit the
            child&apos;s needs. Topics in domains ranging from motor training to
            social community interactions will be prepared in all possible
            formats and uploaded to the system. Authorized schools and special
            educators can access the content free of cost. In all, it will be a
            platform wherein the students&apos; performance, behaviors, and
            progress can be tracked, and a holistic learning environment is
            provided.
          </p>
        </div>
        <div>
          <TopCategories2 />
        </div>
        {/* <div className="d-flex justify-content-center">
          <div>
            <div className="py-4">
              <img className="quote img-fluid home3Slide" alt="" src={Quote} />
            </div>
            <div className="home-three-content" data-aos="fade-up">
              <p className="qoutation-paragraph">
                The highest education is that which does not merely gives
                information but brings our life in harmony with all existence.
              </p>
              <strong>~ Rabindranath Tagore</strong>
            </div>
          </div>
        </div> */}

        <div className="home-three-head mt-5 mb-3" data-aos="fade-up">
          <h2>Salient features</h2>
        </div>
        <div className="d-flex mb-5 justify-content-center">
          <img className="w-75" src={features} data-aos="fade-up" />
        </div>
        <div className="home-three-head mt-5 mb-3" data-aos="fade-up">
          <h2>The framework</h2>
        </div>
        <div className="d-flex mb-5 justify-content-center">
          <img className="w-75" src={chart1} data-aos="fade-up" />
        </div>
      </div>
    </div>
  );
};

export default Edeekshaam;
