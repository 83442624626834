// import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

// import uttarpradesh from '../stats/up2.svg'
// import jammuAndKashmir from '../stats/j_k-1.png'
// import tamilNadu from '../stats/CHENNAI.svg'
// import westBengal from '../stats/Emblem_of_West_Bengal_(2018-present).png'
// import maharastra from '../stats/maharashtra.png'
// import kerala from '../stats/kerala.svg'
// import telangana from '../stats/telangana.png'
// import andhra from '../stats/andhra pradesh.svg'
// import haryana from '../stats/haryana.svg'
// export const TopCategories2 = () => {
//   var course = {
//     //autoWidth: true,
//     items: 2,
//     margin: 25,
//     dots: true,
//     nav: true,
//     navText: [
//       '<i className="fas fa-arrow-left"></i>',
//       '<i className="fas fa-arrow-right"></i>',
//     ],

//     loop: true,
//     autoplay:true,
//     responsiveClass: true,
//     responsive: {
//       0: {
//         items: 1,
//       },
//       768: {
//         items: 5,
//       },
//       1170: {
//         items: 5,
//       },
//     },
//   };
//   return (
//     <OwlCarousel
//       {...course}
//       className="owl-carousel home-three-favourite-carousel owl-theme aos topCat"
//     >
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={uttarpradesh}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Uttar Pradesh</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">92+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">10+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">15+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={jammuAndKashmir }
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Jammu & Kashmir</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">25+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">4+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">6+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={tamilNadu }
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Tamil Nadu</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">20+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">2+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">3+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={westBengal}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>West Bengal</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">10+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">2+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">3+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>

//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={maharastra}
//               alt="Angular Development"
//               style={{scale:'1.5'}}
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Maharastra</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">20+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">4+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">9+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={kerala}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Kerala</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">25+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">4+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">8+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={telangana}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Telangana</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">30+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">4+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">15+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={andhra}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Andhra Pradesh</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">19+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">3+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">10+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="favourite-box" data-aos="fade-down">
//         <div className="favourite-item flex-fill">
//           <div className="categories-icon">
//             <img
//               className="img-fluid"
//               src={haryana}
//               alt="Angular Development"
//             />
//           </div>
//           <div className="categories-content course-info mb-0">
//             <h3>Haryana</h3>
//           </div>
//           <div className="course-instructors">
//             <div className="instructors-info row m-0 p-0">
//               <div className="border border-1 border-start-0 border-top-0 border-end-1 col-6 p-2">
//                 <p className="m-0 p-0 text-center w-auto">15+</p>
//                 <p className="text-center w-auto">Students</p>
//               </div>
//               <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
//                 <p className="m-0 p-0 text-center">2+</p>
//                 <p className="text-center">Institutions</p>
//               </div>
//               <div className="col-12 border border-0 p-2">
//                 <p className="m-0 p-0 text-center">3+</p>
//                 <p className="m-0 p-0 text-center">Rehab professionals</p>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//     </OwlCarousel>
//   );
// };

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import uttarpradesh from "../stats/Uttar_Pradesh.svg.png";
import jammuAndKashmir from "../stats/j_k-1.png";
import tamilNadu from "../stats/CHENNAI.svg";
import westBengal from "../stats/Emblem_of_West_Bengal_(2018-present).png";
import maharastra from "../stats/Maharashtra.svg.png";
import kerala from "../stats/Kerala.svg.png";
import telangana from "../stats/telangana.png";
import haryana from "../stats/haryana.svg";
import bihar from "../stats/bihar.svg";
import delhi from "../stats/Delhi.svg.png";
import odisha from "../stats/Odisha.svg.png";
import jharkhand from "../stats/Jharkhand.svg.png";
import madhyaPradesh from "../stats/Madhya_Pradesh.svg.png";
import andhraPradesh from "../stats/Andhra_Pradesh.svg.png";
import rajasthan from "../stats/Rajasthan.png";
import arunachalPradesh from "../stats/ArunachalPradesh.svg";
import assam from "../stats/Assam.svg.png";
import chhattisgarh from "../stats/Chhattisgarh.svg";
import goa from "../stats/Goa.png";
import gujarat from "../stats/Gujarat.png";
import himachalPradesh from "../stats/Himachal_Pradesh_seal.svg.png";
import karnataka from "../stats/Karnataka.svg.png";
import manipur from "../stats/Manipur.png";
import meghalaya from "../stats/Meghalay.png";
import mizoram from "../stats/Mizoram.png";
import nagaland from "../stats/Nagaland.png";
import punjab from "../stats/Punjab.svg.png";
import sikkim from "../stats/Sikkim.svg.png";
import tripura from "../stats/Tripura.png";
import uttarakhand from "../stats/Uttarakhand.svg.png";
import andamanAndNicobarIslands from "../stats/andaman.png";
import chandigarh from "../stats/Chandigarh.png";
import dadraAndNagarHaveli from "../stats/DadraAndNagarHaveli.png";
import ladakh from "../stats/Ladakh.png";
import pondicherry from "../stats/puducherry.png";
import puducherry from "../stats/puducherry.png";
import lakshadweep from "../stats/Lakshadweep.png";

import india from "../stats/india3.png";

export const TopCategories2 = () => {
  const [state, setState] = useState({});
  const [showDaata] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  var course = {
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],
    loop: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 5,
      },
      1170: {
        items: 5,
      },
    },
  };

  const imagePaths = {
    india: india,
    "uttar pradesh": uttarpradesh,
    "jammu & kashmir": jammuAndKashmir,
    "tamil nadu": tamilNadu,
    "west bengal": westBengal,
    maharashtra: maharastra,
    kerala: kerala,
    telangana: telangana,
    haryana: haryana,
    delhi: delhi,
    bihar: bihar,
    odisha: odisha,
    jharkhand: jharkhand,
    rajasthan: rajasthan,
    "madhya pradesh": madhyaPradesh,
    "andhra pradesh": andhraPradesh,
    "arunachal pradesh": arunachalPradesh,
    assam: assam,
    chhattisgarh: chhattisgarh,
    goa: goa,
    gujarat: gujarat,
    "himachal pradesh": himachalPradesh,
    karnataka: karnataka,
    manipur: manipur,
    mizoram: mizoram,
    meghalaya: meghalaya,
    nagaland: nagaland,
    punjab: punjab,
    sikkim: sikkim,
    tripura: tripura,
    uttarakhand: uttarakhand,
    "andaman and nicobar islands": andamanAndNicobarIslands,
    Chandigarh: chandigarh,
    "dadra and nagar haveli and daman and diu": dadraAndNagarHaveli,
    ladakh: ladakh,
    pondicherry: pondicherry,
    puducherry: puducherry,
    lakshadweep: lakshadweep,
  };

  const getAllDataStateWise = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/count/userStateWise`
      );
      const data = response?.data?.data;
      setState(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllDataStateWise();
  }, []);

  function capitalizeWords(stateName1) {
    return stateName1
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const renderStates = () => {
    if (!state) return null;

    return Object.entries(state).map(([stateName, stateData]) => {
      const imagePath = imagePaths[stateName] || imagePaths["India"];
      return (
        <div key={stateName} className="favourite-box" data-aos="fade-down">
          <div className="favourite-item flex-fill">
            <div className="categories-icon">
              <img className="img-fluid" src={imagePath} alt={stateName} />
            </div>
            <div className="categories-content course-info mb-0">
              <h3>{capitalizeWords(stateName)}</h3>
            </div>
            {!showDaata ? (
              <div className="course-instructors">
                <div className="instructors-info row m-0 p-0">
                  <div className="border border-1 border-start-0  border-top-0 border-end-1  col-6 p-2">
                    <p className="m-0 p-0 text-center w-auto">
                      {stateData?.Student || 0}
                    </p>
                    <p className="text-center w-auto">Students</p>
                  </div>
                  <div className="border border-1 border-start-0 border-end-0 border-top-0 col-6 p-2">
                    <p className="m-0 p-0 text-center">
                      {stateData?.Institute || 0}
                    </p>
                    <p className="text-center">Institutions</p>
                  </div>
                  <div className="border border-1 border-start-0 border-top-0  border-end-1 col-6 p-2">
                    <p className="m-0 p-0 text-center w-auto">
                      {stateData?.Educator || 0}
                    </p>
                    <p className="text-center w-auto">Educators</p>
                  </div>
                  <div className="border border-1 border-start-0 border-end-0  border-top-0 col-6 p-2">
                    <p className="m-0 p-0 text-center">
                      {stateData?.Parent || 0}
                    </p>
                    <p className="text-center">Parents</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <OwlCarousel
          {...course}
          className="owl-carousel home-three-favourite-carousel owl-theme aos topCat"
        >
          {renderStates()}
        </OwlCarousel>
      )}
    </>
  );
};
