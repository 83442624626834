/**
 * Formats a URL by ensuring there is exactly one slash between the base URL and the path.
 * @param {string} baseUrl - The base URL.
 * @param {string} path - The path to be appended to the base URL.
 * @returns {string} - The formatted URL.
 */
export const formatUrl = (baseUrl, path) => {
  // Ensure baseUrl does not end with a slash
  const trimmedBaseUrl = baseUrl.replace(/\/+$/, "");
  // Ensure path does not start with a slash
  const trimmedPath = path.replace(/^\/+/, "");

  return `${trimmedBaseUrl}/${trimmedPath}`;
};
