import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const OtpInput = ({ otp, setOtp, length = 6 }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    const inputRef = inputRefs.current[index];
    if (inputRef) {
      inputRef.setSelectionRange(1, 1);
      if (index > 0 && !otp[index - 1]) {
        const nextIndex = otp.indexOf("");
        if (nextIndex !== -1) {
          inputRefs.current[nextIndex].focus();
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          ref={(input) => {
            inputRefs.current[index] = input;
          }}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onClick={() => handleClick(index)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          className="otpInput"
        />
      ))}
    </div>
  );
};


OtpInput.propTypes = {
  otp: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOtp: PropTypes.func.isRequired,
  length: PropTypes.number,
  onOtpSubmit: PropTypes.func,
};

OtpInput.defaultProps = {
  length: 6,
  onOtpSubmit: () => {},
};

export default OtpInput;