import React, { useEffect } from "react";
import Icon22 from "../header/headerImage/icon-22.svg";
import Icon23 from "../header/headerImage/icon-23.svg";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="main-wrapper">
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                {/* Blog Post */}
                <div className="blog">
                  {/* <p>Data Received: {receivedData}</p> */}

                  <h3 className="blog-title">
                    {/* {workshopData.title} */}
                    One-Day Workshop on Pedagogy and Curriculum Needs of
                    Children with Moderate Intellectual Disability
                  </h3>
                  <div className="blog-info clearfix">
                    <div className="post-left">
                      <ul>
                        <li>
                          <img className="img-fluid" src={Icon22} alt="" />
                          22 April 2022
                        </li>
                        <li>
                          <img className="img-fluid" src={Icon23} alt="" />
                          Events, Workshops
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="blog-content">
                    <p>
                      A one-day workshop was organized specifically targeting
                      the pedagogical and curriculum needs of children with
                      moderate intellectual disabilities. The event was a
                      response to the growing recognition of the unique
                      challenges faced by this group of learners in mainstream
                      educational settings. By dedicating a day to delve into
                      these issues, the workshop aimed to shed light on
                      effective strategies for addressing the diverse learning
                      needs of children with moderate intellectual disabilities.
                    </p>
                    <p>
                      The participants of the workshop encompassed a wide
                      spectrum of stakeholders involved in the education and
                      care of children with moderate intellectual disabilities.
                      Educators, special education specialists, psychologists,
                      caregivers, and possibly parents or guardians all
                      contributed their expertise and experiences to the
                      discussions. This diverse representation ensured a
                      comprehensive exploration of the multifaceted challenges
                      associated with educating children with moderate
                      intellectual disabilities.
                    </p>
                    {/* <h4>What Is a Software Design Document?</h4> */}

                    <div className="video-container rounded-4 overflow-hidden">
                      <div
                        style={{
                          position: "relative",
                          width: "1px",
                          minWidth: "100%",
                          paddingBottom: "56.25%",
                          marginBottom: "25px",
                        }}
                      >
                        <iframe
                          title="video-frame"
                          allow="autoplay"
                          className="spotlightr"
                          style={{
                            width: "1px",
                            minWidth: "100%",
                            height: "100%",
                            position: "absolute",
                          }}
                          allowFullScreen="true"
                          src="https://iframe.mediadelivery.net/embed/238731/dadba2fa-30a7-4705-b50a-e7740c0d7049?autoplay=false&loop=false&muted=false&preload=true&responsive=true"
                          name="videoPlayer"
                        />
                      </div>
                    </div>
                    <p>
                      Throughout the workshop, the participants engaged in rich
                      dialogues and exchanges centered around key topics
                      relevant to pedagogy and curriculum development. One focal
                      point of discussion was the adaptation of teaching
                      methodologies to accommodate the varying learning styles
                      and abilities within this demographic. Strategies for
                      creating inclusive learning environments that promote
                      active participation and meaningful engagement were also
                      explored in depth.
                    </p>
                    <p>
                      In addition to addressing pedagogical concerns, the
                      workshop delved into the intricacies of curriculum design
                      for children with moderate intellectual disabilities.
                      Participants examined approaches for developing curriculum
                      materials that are accessible, relevant, and engaging for
                      learners with diverse cognitive profiles. Emphasis was
                      placed on the importance of individualized learning plans
                      tailored to the unique strengths, interests, and
                      challenges of each child.
                    </p>
                    {/* <h4>What to Include in Your Software Design Document</h4> */}
                    <p>
                      The workshop served as a platform for sharing best
                      practices and evidence-based interventions proven to
                      enhance the educational experiences of children with
                      moderate intellectual disabilities. Participants had the
                      opportunity to showcase successful initiatives implemented
                      in their respective educational settings and exchange
                      insights on what works effectively in practice. By
                      leveraging collective expertise and experiences, the
                      workshop aimed to facilitate the dissemination of
                      innovative approaches that can be replicated and scaled
                      across diverse educational contexts.
                    </p>
                    <p>Here are some factors:</p>
                    <p>
                      <strong>Event:</strong> The workshop was a one-day event,
                      indicating it was a focused session rather than a longer
                      conference or seminar.
                    </p>
                    <p>
                      <strong>Purpose:</strong> The primary objective of the
                      workshop was to discuss the specific educational needs of
                      children with moderate intellectual disabilities. This
                      suggests a focus on understanding how to best teach and
                      support these children in their learning.
                    </p>
                    <p>
                      <strong>Participants:</strong> The workshop likely brought
                      together educators, specialists, caregivers, and possibly
                      parents or guardians of children with moderate
                      intellectual disabilities. These participants would share
                      insights, experiences, and knowledge related to teaching
                      and supporting children with such disabilities.
                    </p>
                    <p>
                      <strong>Topics Covered:</strong> The workshop likely
                      covered various aspects related to pedagogy and curriculum
                      design tailored to the needs of children with moderate
                      intellectual disabilities. This could include strategies
                      for adapting teaching methods, creating inclusive learning
                      environments, and designing curriculum materials that meet
                      the diverse needs of these children.
                    </p>
                    <p>
                      <strong>Outcomes:</strong> The expected outcome of the
                      workshop would be to generate ideas, strategies, and
                      recommendations for improving the educational experiences
                      and outcomes of children with moderate intellectual
                      disabilities. Participants may have developed action plans
                      or identified areas for further research and
                      collaboration. Overall, the workshop served as a platform
                      for stakeholders to come together, share knowledge and
                      expertise, and work towards enhancing the educational
                      opportunities for children with moderate intellectual
                      disabilities.
                    </p>
                  </div>
                </div>
                {/* /Blog Post */}
              </div>
            </div>
          </div>
        </section>

        {/* <Footer/> */}
      </div>
    </>
  );
};

export default BlogDetails;
