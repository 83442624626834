import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import Icon22 from "../header/headerImage/icon-22.svg";
import Icon23 from "../header/headerImage/icon-23.svg";
import { formatUrl } from "../../utils/utils";
import { Helmet } from "react-helmet";

const Workshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const getAlleventsdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/events/getevents/workshop`
      );
      setEvents(response.data.response);
    } catch (error) {
      console.error("Error fetching data:", error);
      setEvents("");
    }
  };

  useEffect(() => {
    getAlleventsdata();
  }, []);

  const naviageHandler = (event) => {
    navigate(`/event/${event.id}`, { state: { videoUrl: event.videoUrl } });
  };

  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="main-wrapper">
      <Helmet>
        <title>Events - Workshops</title>
        <meta
          name="description"
          content="Stay updated with the latest workshops, seminars, and events related to special education and mental health. Join events to learn, collaborate, and grow with edeekshaam."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/workshop" />
      </Helmet>
      <div className="page-banner mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">Workshop</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="course-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="row">
                  {Array.isArray(events) && events.length > 0 ? (
                    events.map((event) => (
                      <div className="col-md-4 col-sm-12" key={event.id}>
                        <div className="blog grid-blog">
                          <div className="blog-image">
                            <img
                              className="img-fluid"
                              src={formatUrl(
                                process.env.REACT_APP_API_URL,
                                event.thumbnailUrl
                              )}
                              alt="PostImage"
                              style={{ height: "250px", cursor: "pointer" }}
                              crossOrigin="anonymous"
                              onClick={() => naviageHandler(event)}
                            />
                          </div>
                          <div className="blog-grid-box">
                            <div className="blog-info clearfix">
                              <div className="post-left">
                                <ul>
                                  <li>
                                    <img
                                      className="img-fluid"
                                      src={Icon22}
                                      alt="PostImage"
                                    />
                                    {new Date(event.date).toLocaleDateString()}
                                  </li>
                                  <li>
                                    <img
                                      className="img-fluid"
                                      src={Icon23}
                                      alt="PostImage"
                                    />
                                    {capitalize(event.type)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <a href="" onClick={() => naviageHandler(event)}>
                              <h3
                                className="blog-title"
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {event.name}
                              </h3>
                            </a>

                            <div className="blog-content blog-read">
                              <p
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {event.content}
                              </p>
                              <div
                                className="read-more btn btn-primary"
                                onClick={() => naviageHandler(event)}
                              >
                                Read More
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="d-flex justify-content-center align-items-center text-xl">
                      Loading...
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Workshop;
