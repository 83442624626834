import { Helmet } from "react-helmet";
import { TopCategories2 } from "./Carousel";

const Manuu = () => {
  return (
    <div className="mb-5">
      <Helmet>
        <title>About MANUU - Maulana Azad National Urdu University</title>
        <meta
          name="description"
          content="Discover Maulana Azad National Urdu University (MANUU), a central university dedicated to promoting Urdu language and inclusive education through innovative teaching and research."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/about-manuu" />
      </Helmet>
      <div className="page-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">About MANUU</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div>
          <h2 className="text-center">Maulana Azad National Urdu University</h2>
          <h3 className="text-center" style={{ color: "#777" }}>
            (A Central University, Hyderabad, India)
          </h3>
          <p style={{ textAlign: "justify" }} className="lh-lg mt-3">
            Maulana Azad National Urdu University (MANUU) is a central
            university established by an Act of the Indian Parliament in the
            year 1998, with all India Jurisdiction, to promote and impart
            research, vocational and technical education through regular and
            distance modes. It is situated in the IT hub of the ever-active city
            of Hyderabad. The University has 7 Schools of Studies, 19
            departments, and 8 Colleges of Teacher Education. It also has five
            polytechnics, two satellite campuses, 14 centres and directorates
            for education and research. By acknowledging the efforts of the
            university in research and education, National Assessment and
            Accreditation Council (NAAC), has awarded an ‘A+’ grade to the
            University.
          </p>
        </div>
        <div className="mt-3">
          <TopCategories2 />
        </div>
        <div>
          <h2 className="mt-3" style={{ textAlign: "justify" }}>
            Department of Computer Science & Information Technology
          </h2>
          <p style={{ textAlign: "justify" }} className="lh-lg">
            Department of Computer Science & Information Technology (CS&IT)
            under the ‘School of Technology’ was established in 2006. The
            department has state-of-the-art ICT infrastructure . At present, the
            department is offering B.Tech, M.Tech, MCA, and PhD programmes in
            Computer Science and Information Technology. All these programs are
            approved by All India Council for Technical Education (AICTE).
            Currently, there are 25 faculty members working in the Department of
            CS&IT conducting teaching and research under various areas of CS/IT.
            The Department of CS&IT has focused on research in various
            cutting-edge areas such as machine translation, adaptive systems,
            personalized learning, bio-informatics, road dynamics, computer
            vision, web semantics, disease prediction, smart computing,
            networking, security etc.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Manuu;
