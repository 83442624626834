import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Icon22 from "../header/headerImage/icon-22.svg";
import Icon23 from "../header/headerImage/icon-23.svg";

const OtherEventDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const location = useLocation();
  const { videoUrl } = location.state || {};
  const { id } = useParams();
  const [event, setEvent] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/events/getevent/${id}`
        );
        setEvent(response.data.response);
      } catch (error) {
        console.error("Error fetching data:", error);
        setEvent(null);
      }
    };

    getDetails();
  }, [id]);

  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className="main-wrapper">
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                <div className="blog">
                  <h3 className="blog-title">{event.name}</h3>
                  <div className="blog-info clearfix">
                    <div className="post-left">
                      <ul>
                        <li>
                          <img className="img-fluid" src={Icon22} alt="img" />
                          {new Date(event.date).toLocaleDateString()}
                        </li>
                        <li>
                          <img className="img-fluid" src={Icon23} alt="" />
                          {capitalize(event.type)}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="blog-content"
                    style={{ textAlign: "justify" }}
                  >
                    <p>{event.content}</p>
                  </div>
                  <div className="video-container rounded-4 overflow-hidden">
                    <div
                      style={{
                        position: "relative",
                        width: "1px",
                        minWidth: "100%",
                        paddingBottom: "56.25%",
                        marginBottom: "25px",
                      }}
                    >
                      {videoUrl && (
                        <iframe
                          title="video-frame"
                          allow="autoplay"
                          className="spotlightr"
                          style={{
                            width: "1px",
                            minWidth: "100%",
                            height: "100%",
                            position: "absolute",
                          }}
                          allowFullScreen
                          src={videoUrl}
                          name="videoPlayer"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OtherEventDetails;
