// AppRouter.jsx

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/home/index";
import ContactUs from "./components/contactUS/index";
import Collaboration from "./components/collaboration/index";
import ImportantLinks from "./components/importantLinks/ImportantLinks";
import Manuu from "./components/about/manuu/Manuu";
import Edeekshaam from "./components/about/edeekshaam/Edeekshaam";
import OtherEvents from "./components/otherEvents/index";
import Workshop from "./components/workshop/index";
import Header from "./components/header/index";
import Footer from "./components/footer/index";
import Disclaimer from "./components/disclaimer/Disclaimer";
import Terms from "./components/terms/Terms";
import OtherEventDetails from "./components/otherEvents/OtherEventDetails";
import BlogDetails from "./components/workshop/blogDetails";
import BlogDetails1 from "./components/workshop/blogDetails1";
import BlogDetails2 from "./components/workshop/blogDetails2";
import NotFound from "./components/error404/404";

const AppRouter = () => {
  return (
    <Router basename="/">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/collaboration" element={<Collaboration />} />
        <Route path="/important-links" element={<ImportantLinks />} />
        <Route path="/about-edeeksham" element={<Edeekshaam />} />
        <Route path="/about-manuu" element={<Manuu />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/other-events" element={<OtherEvents />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/blog-details1" element={<BlogDetails1 />} />
        <Route path="/blog-details2" element={<BlogDetails2 />} />
        <Route path="/event/:id" element={<OtherEventDetails />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
