import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Error1 from "../../assets/img/error-01.png";

const Error404 = () => {
  useEffect(() => {
    document.body.classList.add("error-page");
    return () => document.body.classList.remove("error-page");
  }, []);

  return (
    <>
      <div className="main-wrapper py-4">
        <div className="error-box">
          <div className="error-box-img">
            <img src={Error1} alt="" className="img-fluid" />
          </div>
          <h3 className="h2 mb-3"> Oh No! Error 404</h3>
          <p className="h4 font-weight-normal">
            Oops! It looks like the page you were looking for doesn&apos;t exist
            or has been moved.
          </p>
          <p className="h4 font-weight-normal">
            If you believe this is an error, please contact support
            <a href="mailto:contact@edeekshaam.in"> contact@edeekshaam.in</a>
          </p>
          <Link to="/" className="btn btn-primary">
            Back to Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Error404;
